import hello from './hello';

$(()=>{
  $('.title').text(hello());
$('.your-class').slick({});


  var startPos = 0;
  if(isMobile()){
    $(window).scroll(function(){

      setTimeout(function(){
        var currentPos = $(this).scrollTop();
        if (currentPos > startPos + 20) {
          if($(window).scrollTop() >= 100) {
            $(".fixed-top").fadeOut();
          }
        } else if (startPos - 40 > currentPos) {

          $(".fixed-top").fadeIn();
        }
        startPos = currentPos;
      }, 100);
    });
  }



});

$(function() {
  $('.popup-alert').hide();
  $('.popup-alert').fadeIn();
  setTimeout(function(){
    $('.popup-alert').fadeOut();
  }, 3000);
  $('.password-change-show-btn').click(function () {
    $('.password-change-field').removeClass('password-change-field');
    $(this).hide();
    return false;
  })


  $('.change-address').click((e)=>{
    e.preventDefault();
    $('.address-edit').fadeIn();
    $('.address-preset').hide();


  })


});



function isMobile(){
  var ua = navigator.userAgent;
  if ((ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0) && ua.indexOf('Mobile') > 0) {
    return true;
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return false;
  } else {
    return false;
  }

}
