import 'chart.js/dist/chart'

function celsiusToFahrenheit(celsius) {
  return (celsius * 9 / 5) + 32;
}

$(function () {

  var labels = [];
  var temp = [];
  var humidity = [];
  var dewpoint = [];
  if (document.getElementById('weatherCanvus') == null) {
    return;
  }
  if (document.getElementById('locale').innerText == 'us') {
    var data = $.get('/api/v1/weather', function (data) {
      data["weather_records"].forEach(element => {
        labels.push(element['date']);
        temp.push(celsiusToFahrenheit(parseFloat(element['temp'])));
        humidity.push(celsiusToFahrenheit(parseInt(element['humidity'])));
        dewpoint.push(parseFloat(element['dewpoint']));
      });

      var ctx = document.getElementById('weatherCanvus');
      $("#weatherCanvus").css("height", 300);
      var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Temperature(F)',
              data: temp,

              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              yAxisID: 'y',
            },
            {
              label: 'Dew Point(F)',
              data: dewpoint,
              borderColor: 'rgba(54, 162, 235, 1)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              yAxisID: 'y',
            },
            {
              label: 'Humidity(%)',
              data: humidity,
              borderColor: 'rgba(255, 206, 86, 1)',
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              yAxisID: 'y1',
            }



          ]


        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          stacked: false,
          plugins: {
          },
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              beginAtZero: true,
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },

            },
          }
        }
      });
    });

  } else {

    var data = $.get('/api/v1/weather', function (data) {
      data["weather_records"].forEach(element => {
        labels.push(element['date']);
        temp.push(parseFloat(element['temp']));
        humidity.push(parseInt(element['humidity']));
        dewpoint.push(parseFloat(element['dewpoint']));
      });

      var ctx = document.getElementById('weatherCanvus');
      $("#weatherCanvus").css("height", 300);
      var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: '気温(℃)',
              data: temp,

              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              yAxisID: 'y',
            },
            {
              label: '露点(℃)',
              data: dewpoint,
              borderColor: 'rgba(54, 162, 235, 1)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              yAxisID: 'y',
            },
            {
              label: '湿度(%)',
              data: humidity,
              borderColor: 'rgba(255, 206, 86, 1)',
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              yAxisID: 'y1',
            }



          ]


        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          stacked: false,
          plugins: {
          },
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              beginAtZero: true,
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },

            },
          }
        }
      });
    });
  }

});
