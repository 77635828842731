import 'bootstrap/dist/js/bootstrap';
import 'slick-carousel/slick/slick';
import 'wowjs/dist/wow';
import 'waypoints/lib/noframework.waypoints';
import './hello_app';
import './loadWowjs';
import './weather'
import './checkoutOption'
import './checkOverTheLegalAge'


$(document).ready(function () {
  $(document).on('turbolinks:render', function () {
    if ($('.h-adr').length) {
      new YubinBango.MicroformatDom();
    }
  });
});
