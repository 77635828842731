$(()=>{
  $('#check-for-over-the-legal-age').on('click', (e)=>{
      var checked = document.getElementById('check-for-over-the-legal-age').checked;

      if (checked) {
        document.getElementById('submit-for-checkout').classList.remove('disabled');        
      } else {
        document.getElementById('submit-for-checkout').classList.add('disabled');
      }
  })

});
