$(()=>{
    $('.checkout-option').on('click', (e)=>{
        var coolOption = document.getElementById('coolCheckOption').checked;
        var giftOption = document.getElementById('giftCheckOption').checked;

        $.post(
            'checkout_option',
            {
                "cool": coolOption,
                "gift": giftOption
            }, 
            (data)=>{
                console.log(data);
            }
        );
    })

});
